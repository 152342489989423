import React, { useState } from 'react';
import { Element } from 'react-scroll';

const plans = {
  monthly: [
    {
      id: 1,
      name: 'SKillsboost (BASIC)',
      price: '$29.99',
      priceType: 'Monthly',
      buttonText: 'Buy This Plan',
      purchaseLink: '#',
      features: [
        'Online Courses',
        'Industry-Recognized Certification',
        'Webinars & Workshops',
        'Community Access',
        'Job Board Access'
      ]
    },
    {
      id: 2,
      name: 'AI Ready (PROFESSIONAL)',
      price: '$39.99',
      priceType: 'Monthly',
      buttonText: 'Buy This Plan',
      purchaseLink: '#',
      active: true,
      badgeText: 'POPULAR',
      features: [
        'All of Basic and',
        'Advanced Courses & Specializations',
        'Work Integrated Learning Projects',
        'Mentorship Program',
        'Job Placement Assistance'
      ]
    },
    {
      id: 3,
      name: 'CareerLaunch (PREMIUM)',
      price: '$49.99',
      priceType: 'Monthly',
      buttonText: 'Buy This Plan',
      purchaseLink: '#',
      features: [
        'All of Professional and',
        'Guaranteed Internship Placement',
        'Personalized Career Coaching',
        'Premium Networking Opportunities',
        'Extended Access & Updates'
      ]
    }
  ],
  yearly: [
    {
      id: 1,
      name: 'Skillsboost (Basic)',
      price: '$700',
      priceType: ' + applicable tax',
      buttonText: 'Buy This Plan',
      purchaseLink: 'https://courses.m2mtechconnect.com/enroll/3085976',
      features: [
        'Online Courses',
        'Industry-Recognized Certification',
        'Webinars & Workshops',
        'Community Access',
        'Job Board Access'
      ]
    },
    {
      id: 2,
      name: 'AI Ready (Professional)',
      price: '$1400',
      priceType: ' + applicable tax',
      buttonText: 'Buy This Plan',
      purchaseLink: 'https://courses.m2mtechconnect.com/enroll/3086000',
      active: true,
      badgeText: 'POPULAR',
      features: [
        'All of Basic features, and',
        'Advanced Courses & Specializations',
        'Work Integrated Learning Projects',
        'Mentorship Program',
        'Job Placement Assistance'
      ]
    },
    {
      id: 3,
      name: 'CareerLaunch (Premium)',
      price: '$3500',
      priceType: ' + applicable tax',
      buttonText: 'Buy This Plan',
      purchaseLink: 'https://courses.m2mtechconnect.com/enroll/3086016',
      features: [
        'All of Professional features, and',
        'Guaranteed Internship Placement',
        'Personalized Career Coaching',
        'Premium Networking Opportunities',
        'Extended Access & Updates'
      ]
    }
  ]
};

const IndustryAlignedUpskillingPackages = () => {
  const { monthly, yearly } = plans;
  const [state, setState] = useState( {
    active: 'yearly',
    pricingPlan: yearly,
  } );
  const handlePricing = ( plan ) => {
    if ( plan === 'yearly' ) {
      setState( {
        ...state,
        active: 'yearly',
        pricingPlan: yearly
      } );
    } else {
      setState( {
        ...state,
        active: 'monthly',
        pricingPlan: monthly
      } );
    }
  }

  return (
    <>
      <Element className="edu-section-gap style-variation3 blog-main-content">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-lg-8">
              <div className="section-title text-start">
                  <span className="pre-title">Pricing Plan</span>
                  <h3 className="title">Choose Your Subscription Package</h3>
              </div>
            </div>
            <div className="col-lg-4">
              {/* <div className="pricing-billing-duration">
                <ul>
                  <li className="nav-item">
                    <button
                      className={state.active === 'yearly' ? 'nav-link active' : 'nav-link'}
                      type="button"
                      aria-label="Yearly"
                      onClick={() => handlePricing('yearly')}
                    >
                      Yearly
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={state.active === 'monthly' ? 'nav-link active' : 'nav-link'}
                      type="button"
                      aria-label="Monthly"
                      onClick={() => handlePricing('monthly')}
                    >
                      Monthly
                    </button>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <div className="row g-5 mt--20">
            { state.active === 'monthly' && 
              state.pricingPlan.map( (data, index) => (
                <div className="col-lg-4 col-md-6 col-12" key={ index }>
                  <div className={ `pricing-table ${data.active === true ? ' active' : ''}` }>
                    <div className="pricing-header">
                      {
                        data.active === true && <div className="edu-badge"><span>{ data.badgeText }</span></div>
                      }
                      <h3 className="title">{ data.name }</h3>
                      <div className="price-wrap">
                        <div className="yearly-pricing">
                          <span className="amount">{ data.price }</span>
                          <span className="duration">/{ data.priceType }</span>
                        </div>
                      </div>
                    </div>
                    { data.features && data.features.length > 0 && 
                      <div className="pricing-body">
                        <ul className="list-item">
                          {
                            data.features.map( ( feature, i ) => <li key={i}><i className="icon-checkbox-circle-line"></i>{ feature }</li> )
                          }
                        </ul>
                      </div>
                    }
                    <div className="pricing-btn">
                      <a className={ `edu-btn ${data.active !== true ? ' btn-dark' : ''}` } href={ data.purchaseLink }>
                        { data.buttonText }<i className="icon-arrow-right-line-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ) )
            }
            { state.active === 'yearly' && 
              state.pricingPlan.map( (data, index) => (
                <div className="col-lg-4 col-md-6 col-12" key={ index }>
                  <div className={ `pricing-table ${data.active === true ? ' active' : ''}` }>
                    <div className="pricing-header">
                      {
                        data.active === true && <div className="edu-badge"><span>{ data.badgeText }</span></div>
                      }
                      <h3 className="title">{ data.name }</h3>
                      <div className="price-wrap">
                        <div className="yearly-pricing">
                          <span className="amount">{ data.price }</span>
                          <span className="duration">&nbsp;{ data.priceType }</span>
                        </div>
                      </div>
                    </div>
                    { data.features && data.features.length > 0 && 
                      <div className="pricing-body">
                        <ul className="list-item">
                          {
                            data.features.map( ( feature, i ) => {
                              if(feature.includes("All of")) {
                                return (
                                  <li style={{backgroundColor: "#C6ECE8", borderRadius: "4px", color: "rgb(0, 0, 0)", padding: "0 1rem"}} key={i}><strong>{ feature }</strong></li>
                                )
                              } else {
                                return (
                                  <li key={i}><i className="icon-checkbox-circle-line"></i>{ feature }</li>
                                )}
                              }
                            )
                          }
                        </ul>
                      </div>
                    }
                    <div className="pricing-btn">
                      <a className={ `edu-btn ${data.active !== true ? ' btn-dark' : ''}` } href={ data.purchaseLink }>
                        { data.buttonText }<i className="icon-arrow-right-line-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ) )
            }
          </div>
        </div>
      </Element>
    </>
  )
}

export default IndustryAlignedUpskillingPackages;